import { StepProps } from '@betterment/js-runtime';
import { Box, Button, CenteredLayout, Heading, Icon, PancakeStack, Text } from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import { TestIDProps } from '../../testing/test-id-props';
import { navigate } from '../../utils/path-helpers';

const DoneStep = ({ flowState, testID }: StepProps & TestIDProps) => {
  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Two-Factor Authentication" />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Box>
              <Icon color="icon" name="CheckmarkCircle" size="large" />
              <Heading as="h1" data-testid={testID} css={{ marginBottom: 'space.400' }}>
                You're all set!
              </Heading>

              <Text as="p" css={{ marginBottom: 'space.400' }}>
                From now on, you’ll use your authenticator app as part of signing in to your Betterment account.
              </Text>
              <Button
                type="button"
                onClick={() => navigate(flowState.returnToPath)}
                data-testid={testID ? `${testID}-continue` : null}
              >
                Done
              </Button>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { DoneStep };
