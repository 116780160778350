import React from 'react';

import { StepProps } from '@betterment/js-runtime';
import { Box, Button, CenteredLayout, Heading, Icon, PancakeStack, Text } from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import { navigate } from '../../utils/path-helpers';

const SuccessStep: React.FC<StepProps> = ({ flowState }) => {
  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Two-Factor Authentication" />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Box>
              <Icon color="icon" name="CheckmarkCircle" size="large" />
              <Heading as="h1" css={{ marginBottom: 'space.400' }}>
                You're all set!
              </Heading>
              <Text as="p" css={{ marginBottom: 'space.400' }}>
                From now on, we'll use this number to send you verification codes when you sign in to your Betterment
                account.
              </Text>
              <Button type="button" onClick={() => navigate(flowState.returnToPath)}>
                Done
              </Button>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { SuccessStep };
