import { ChangeEvent, MouseEvent, useState } from 'react';

import { StepProps, useMutation } from '@betterment/js-runtime';
import {
  Banner,
  Box,
  Button,
  CenteredLayout,
  Heading,
  Input,
  Label,
  PancakeStack,
  Text,
} from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import { TotpCodeRequest, TotpCodeVerificationResponse } from './data';

import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import urlBuilder from '../../Shared/url-builder';
import { TestIDProps } from '../../testing/test-id-props';

const VerifyStep = ({ goToPreviousStep, goToNextStep, testID }: StepProps & TestIDProps) => {
  const [mutateVerify] = useMutation<TotpCodeVerificationResponse, TotpCodeRequest>(
    urlBuilder.postTotpSetupConfirmation.absolute()
  );
  const [totpCode, setTOTPCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const changeTOTPCode = (event: ChangeEvent<HTMLInputElement>) => {
    setTOTPCode(event.target.value);
  };
  const clickContinue = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await mutateVerify({ totpCode });
    if (
      !!response.errors &&
      response.errors.status === 422 &&
      response.errors.messages.base[0] === 'TOTP code not verified'
    ) {
      const mError = 'Code not verified';
      setError(mError);
      return;
    }
    if (response.errors) {
      const mError = 'System error';
      setError(mError);
      return;
    }
    const { totpCodeVerified } = response.data as TotpCodeVerificationResponse;
    if (totpCodeVerified !== true) {
      const mError = 'System error';
      setError(mError);
      return;
    }
    goToNextStep();
  };
  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Two-Factor Authentication | 2 of 2" goToPreviousStep={goToPreviousStep} />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1" data-testid={testID}>
              Enter the verification code from your authenticator app.
            </Heading>
            <Box css={{ width: '100%' }}>
              {!!error && (
                <Banner
                  css={{ marginBottom: 'space.400' }}
                  variant="critical"
                  data-testid={testID ? `${testID}-error` : ''}
                >
                  <Banner.Content>
                    <Text as="p">{error}</Text>
                  </Banner.Content>
                  <Banner.Close onClick={() => setError(null)} />
                </Banner>
              )}
              <Box css={{ marginY: 'spacing.400' }}>
                <Label htmlFor="totpCode">Verification code</Label>
                <Input
                  appearance="default"
                  required={true}
                  spacing="default"
                  id="totpCode"
                  onChange={changeTOTPCode}
                  value={totpCode}
                  data-testid={testID ? `${testID}-totp-code` : null}
                  css={{
                    width: '100%',
                    marginBottom: 'space.400',
                  }}
                />
              </Box>
              <Button
                type="button"
                onClick={event => void clickContinue(event)}
                data-testid={testID ? `${testID}-continue` : null}
              >
                Verify
              </Button>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { VerifyStep };
