import { IconButton, Link, Logo, WorkflowHeader } from '@betterment/samba-react';

import urlBuilder from '../url-builder';

type FlowHeaderProps = {
  title: string;
  goToPreviousStep?: () => void;
};

const FlowHeaderWithSettings = ({ title, goToPreviousStep }: FlowHeaderProps) => {
  const renderLogoOrArrow = () => {
    if (goToPreviousStep) {
      return (
        <IconButton
          label="back to previous step"
          name="LeftArrow"
          spacing="default"
          variant="ghost-alt"
          onClick={goToPreviousStep}
        />
      );
    }
    return <Logo data-testid="logo" type="signature" css={{ height: 'size.300' }} />;
  };

  return (
    <WorkflowHeader>
      <WorkflowHeader.Leading>{renderLogoOrArrow()}</WorkflowHeader.Leading>
      <WorkflowHeader.Title>{title}</WorkflowHeader.Title>
      <WorkflowHeader.Trailing>
        <Link data-testid="backToSettingsLink" href={urlBuilder.getSettings()} variant="secondary">
          <IconButton label="back to settings" name="X" spacing="default" variant="ghost-alt" />
        </Link>
      </WorkflowHeader.Trailing>
    </WorkflowHeader>
  );
};

export { FlowHeaderWithSettings };
