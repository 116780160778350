import { FlowStateProvider, Step } from '@betterment/js-runtime';

import { Values } from './data';
import { StartStep } from './StartStep';
import { SuccessStep } from './SuccessStep';
import { VerifyStep } from './VerifyStep';

import { SmsDeliveryMethods } from '../../Shared/data';
import urlBuilder from '../../Shared/url-builder';

const Controller: React.FC = () => {
  const settingsUrl = urlBuilder.getSettings();

  const initialValues: Values = {
    phoneNumber: '',
    code: '',
    deliveryMethod: SmsDeliveryMethods.sms,
  };

  return (
    <FlowStateProvider returnToPath={settingsUrl} initialValues={initialValues}>
      <Step name="start" component={StartStep} />
      <Step name="verify" component={VerifyStep} />
      <Step name="success" component={SuccessStep} />
    </FlowStateProvider>
  );
};

export default Controller;
