import React from 'react';

import { useFlowState, useMutation } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Dialog,
  FormikField,
  Heading,
  Input,
  Logo,
  PancakeStack,
  Text,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet';

import { VERIFICATION_CODE_LENGTH, VerificationCodeSchema } from '../../Shared/data';
import URLBuilder from '../../Shared/url-builder';
import { mapFirstApiErrorMsgToFormikAttribute } from '../../utils/form-helpers';

const TotpVerifyStep: React.FC = () => {
  const { goToNextStep } = useFlowState();
  const [verifyCode, { loading: isConfirmingCode }] = useMutation(
    URLBuilder.postLoginChallengeConfirmation.absolute(),
    {
      method: 'post',
    }
  );

  const onSubmit = async (submitValues: { code: string }, { setErrors }: FormikHelpers<{ code: string }>) => {
    const response = await verifyCode(submitValues);
    if (response.errors) {
      setErrors(mapFirstApiErrorMsgToFormikAttribute(response.errors, 'code'));
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <Helmet>
        <title>Login challenge</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <Logo type="signature" css={{ height: 'size.300' }} />
            </WorkflowHeader.Leading>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout
            size="medium"
            css={{
              gap: 'space.400',
              padding: 'space.400',
              alignItems: 'stretch',
            }}
          >
            <Heading as="h1">Enter verification code</Heading>
            <Box css={{ marginBottom: 'space.200' }}>
              <Text>
                Please enter the {VERIFICATION_CODE_LENGTH}
                -digit verification from your authenticator app.
              </Text>
            </Box>

            <Box>
              <Formik
                initialValues={{ code: '' }}
                validationSchema={VerificationCodeSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                <Form>
                  <FormikField name="code">
                    <FormikField.Label>Verification code</FormikField.Label>
                    <FormikField.Control>
                      <Input />
                    </FormikField.Control>
                    <FormikField.ErrorMessage />
                  </FormikField>
                  <Box css={{ marginTop: 'space.500' }}>
                    <Button type="submit" disabled={isConfirmingCode} css={{ marginBottom: 'space.200' }}>
                      Verify
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Box>
            <Box>
              <Text>
                Not able to verify?{' '}
                <Dialog overlay="default">
                  <Dialog.Trigger asChild={true}>
                    <Button spacing="default" variant="linkPrimary">
                      We can help.
                    </Button>
                  </Dialog.Trigger>
                  <Dialog.Content
                    css={{
                      p: 'space.400',
                    }}
                    size="large"
                  >
                    <Dialog.Title asChild={true}>
                      <Heading as="h1" variant="title200" css={{ marginY: 'space.200' }}>
                        I'm not able to submit a verification code
                      </Heading>
                    </Dialog.Title>
                    <Dialog.Description asChild={true}>
                      <Text variant="text100">
                        If your phone was lost or stolen, please email us at plansupport@betterment.com, and we will
                        help you log in once we verify your identity.
                      </Text>
                    </Dialog.Description>
                  </Dialog.Content>
                </Dialog>
              </Text>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { TotpVerifyStep };
