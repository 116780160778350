import React, { useState } from 'react';

import { StepProps, useFlowState, useMutation } from '@betterment/js-runtime';
import {
  Accordion,
  Box,
  Button,
  CenteredLayout,
  FormikField,
  Heading,
  Input,
  Link,
  PancakeStack,
  Text,
} from '@betterment/samba-react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { Values } from './data';

import { getAppConfig } from '../../../b4b/config';
import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import { NON_PROD_ENVIRONMENTS, SmsDeliveryMethods } from '../../Shared/data';
import urlBuilder from '../../Shared/url-builder';
import { convertApiErrorsToFormikMsg } from '../../utils/form-helpers';

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const PhoneNumberSchema = Yup.string()
  .required('Please enter your phone number')
  .matches(phoneRegExp, 'Must be a valid U.S. phone number');

const StepSchema = Yup.object<Partial<Values>>().shape({
  phoneNumber: PhoneNumberSchema,
});

const StartStep: React.FC<StepProps> = () => {
  const { sentryEnvironment } = getAppConfig();
  const { flowState, goToNextStep } = useFlowState<Values>();
  const [deliveryMethod, setDeliveryMethod] = useState<SmsDeliveryMethods>(SmsDeliveryMethods.sms);
  const [sendSmsCode] = useMutation(urlBuilder.postSmsSetupCodeSend.absolute(), {
    method: 'post',
  });

  const onSubmit = async (formParams: Values, { setErrors }: FormikHelpers<Values>) => {
    const submitParams = { ...formParams, deliveryMethod };
    const response = await sendSmsCode(submitParams);

    if (response.errors) {
      setErrors(convertApiErrorsToFormikMsg(response.errors));
    } else {
      goToNextStep(submitParams);
    }
  };

  return (
    <>
      <Helmet>
        <title>Two-factor authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Two-Factor Authentication | 1 of 2" />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1">Confirm your phone number for two-factor authentication.</Heading>
            <Box css={{ marginBottom: 'space.400' }}>
              <Text as="p">
                We will send verification codes to this phone number. You’ll be asked to enter a verification code each
                time you log in to Betterment from a new device.
              </Text>
              {NON_PROD_ENVIRONMENTS.includes(sentryEnvironment) && (
                <Text as="p" css={{ marginTop: 'space.200' }}>
                  In development no messages are sent, please proceed and the verification code will default to 123456.
                </Text>
              )}
            </Box>

            <Formik
              initialValues={{ ...flowState.values }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={StepSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormikField name="phoneNumber">
                  <FormikField.Label>Phone number</FormikField.Label>
                  <FormikField.Control>
                    <Input mask="phone" name="phoneNumber" ignorePasswordManagers={true} />
                  </FormikField.Control>
                  <FormikField.ErrorMessage />
                </FormikField>

                <Box css={{ display: 'flex', marginTop: 'space.600' }}>
                  <Button type="submit" variant="primary" onClick={() => setDeliveryMethod(SmsDeliveryMethods.sms)}>
                    Text me
                  </Button>
                  <Button
                    type="submit"
                    variant="linkPrimary"
                    css={{ fontWeight: 'medium', marginLeft: 'space.200', alignSelf: 'center' }}
                    onClick={() => setDeliveryMethod(SmsDeliveryMethods.voice)}
                  >
                    Call me instead
                  </Button>
                </Box>
              </Form>
            </Formik>

            <Accordion type="multiple" css={{ minWidth: '300px', marginTop: 'space.200' }}>
              <Accordion.Item value="item-1" css={{ borderBottom: 'none' }}>
                <Accordion.Trigger aria-label="couches">What are my other options?</Accordion.Trigger>
                <Accordion.Content css={{ paddingLeft: 'space.400' }}>
                  <Text variant="text100">
                    You can set up an authenticator app on your smartphone or computer to use when you log in instead of
                    your phone number.{' '}
                    <Link href={urlBuilder.getTotpSetupFlow()}>Verify through your authenticator app.</Link>
                  </Text>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { StartStep };
