import React from 'react';

import { useFlowState, useMutation } from '@betterment/js-runtime';
import { Box, Button, CenteredLayout, FormikField, Heading, Input, PancakeStack, Text } from '@betterment/samba-react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet';

import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import { VerificationCodeSchema } from '../../Shared/data';
import URLBuilder from '../../Shared/url-builder';
import { mapFirstApiErrorMsgToFormikAttribute } from '../../utils/form-helpers';
import { navigate } from '../../utils/path-helpers';

const TotpVerifyStep: React.FC = () => {
  const {
    flowState: { returnToPath },
  } = useFlowState();
  const [verifyCode, { loading: isConfirmingCode }] = useMutation(URLBuilder.postSudoChallengeConfirmation.absolute(), {
    method: 'post',
  });

  const onSubmit = async (submitValues: { code: string }, { setErrors }: FormikHelpers<{ code: string }>) => {
    const response = await verifyCode(submitValues);
    if (response.errors) {
      setErrors(mapFirstApiErrorMsgToFormikAttribute(response.errors, 'code'));
    } else {
      navigate(returnToPath);
    }
  };

  return (
    <>
      <Helmet>
        <title>Authenticate your account</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Authenticate your account" />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1">Your security is important to us</Heading>
            <Box css={{ marginY: 'space.200' }}>
              <Text>
                We need to re-authenticate your account before you continue. Enter the verification code from your
                authenticator app to verify.
              </Text>
            </Box>

            <Box css={{ width: '100%' }}>
              <Formik
                initialValues={{ code: '' }}
                validationSchema={VerificationCodeSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                <Form>
                  <FormikField name="code">
                    <FormikField.Label>Verification code</FormikField.Label>
                    <FormikField.Control>
                      <Input />
                    </FormikField.Control>
                    <FormikField.ErrorMessage />
                  </FormikField>
                  <Box css={{ marginY: 'space.400' }}>
                    <Button type="submit" disabled={isConfirmingCode}>
                      Verify
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { TotpVerifyStep };
