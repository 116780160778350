import React, { MouseEvent, useState } from 'react';

import { StepProps, useFlowState, useMutation } from '@betterment/js-runtime';
import { Box, Button, CenteredLayout, FormikField, Heading, Input, PancakeStack, Text } from '@betterment/samba-react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { Values } from './data';

import { getAppConfig } from '../../../b4b/config';
import { FlowHeaderWithSettings } from '../../Shared/Components/FlowHeaderWithSettings';
import { RedirectToPreviousStep } from '../../Shared/Components/RedirectToPreviousStep';
import { NON_PROD_ENVIRONMENTS, SmsDeliveryMethods, VERIFICATION_CODE_LENGTH } from '../../Shared/data';
import urlBuilder from '../../Shared/url-builder';
import { convertApiErrorsToFormikMsg } from '../../utils/form-helpers';

const Schema = Yup.object().shape({
  code: Yup.string()
    .required('is required')
    .matches(/^[0-9]+$/, `Must be exactly ${VERIFICATION_CODE_LENGTH} digits`)
    .min(VERIFICATION_CODE_LENGTH, `Must be exactly ${VERIFICATION_CODE_LENGTH} digits`)
    .max(VERIFICATION_CODE_LENGTH, `Must be exactly ${VERIFICATION_CODE_LENGTH} digits`),
});

const initialResendMessageState = {
  messageClass: '',
  messageContent: '',
};

const VerifyStep: React.FC<StepProps> = () => {
  const appConfig = getAppConfig();
  const { sentryEnvironment } = appConfig;

  const [resendMessageState, setResendMessageState] = useState(initialResendMessageState);
  const {
    flowState: { values },
    goToPreviousStep,
    goToNextStep,
  } = useFlowState<Values>();

  const [verifyCode] = useMutation(urlBuilder.postSmsSetupCodeConfirmation.absolute(), {
    method: 'post',
  });

  const [resend] = useMutation(urlBuilder.postSmsSetupCodeResend.absolute(), {
    method: 'post',
  });

  if (!values.phoneNumber) {
    return <RedirectToPreviousStep />;
  }

  const handleResend = async (event: MouseEvent, deliveryMethod: string) => {
    event.preventDefault();
    event.stopPropagation();
    setResendMessageState(initialResendMessageState);
    const response = await resend({ deliveryMethod });
    if (response.errors) {
      return void setResendMessageState({
        messageClass: 'u-color-bmtOrange90',
        messageContent: convertApiErrorsToFormikMsg(response.errors).base,
      });
    }
    setResendMessageState({
      messageClass: 'u-color-bmtVizTeal90',
      messageContent: 'Code successfully resent.',
    });
    return true;
  };

  const onSubmit = async (submitValues: { code: string }, { setErrors }: FormikHelpers<{ code: string }>) => {
    const response = await verifyCode(submitValues);
    if (response.errors) {
      setErrors(convertApiErrorsToFormikMsg(response.errors));
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <FlowHeaderWithSettings title="Two-Factor Authentication | 2 of 2" goToPreviousStep={goToPreviousStep} />
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1">Enter verification code.</Heading>
            <Box css={{ marginBottom: 'space.50' }}>
              <Text as="p">
                We just sent a {VERIFICATION_CODE_LENGTH}-digit verification code to {values.phoneNumber}. Make sure the
                phone number you provided is correct and that your phone is turned on and has service.
              </Text>
            </Box>

            {NON_PROD_ENVIRONMENTS.includes(sentryEnvironment) && <p>Use 123456 for development.</p>}

            <Formik
              initialValues={{ code: '' }}
              validationSchema={Schema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={onSubmit}
            >
              <Form>
                <FormikField name="code">
                  <FormikField.Label>Verification code</FormikField.Label>
                  <FormikField.Control>
                    <Input
                      inputMode="numeric"
                      type="string"
                      maxLength={VERIFICATION_CODE_LENGTH}
                      minLength={VERIFICATION_CODE_LENGTH}
                      autoFocus={true}
                    />
                  </FormikField.Control>
                  <FormikField.ErrorMessage />
                </FormikField>
                <Box css={{ marginBottom: 'space.200' }}>
                  <Text className={resendMessageState.messageClass}>{resendMessageState.messageContent}</Text>
                </Box>
                <Button type="submit">Verify</Button>
              </Form>
            </Formik>
            <Box>
              <Text>Haven't received your verification code?</Text>
              <Box css={{ display: 'flex', alignItems: 'center', gap: 'space.100', marginTop: 'space.100' }}>
                <Button
                  variant="linkPrimary"
                  onClick={(event: MouseEvent) => void handleResend(event, SmsDeliveryMethods.sms)}
                  css={{
                    padding: 'space.0',
                  }}
                >
                  Text me again
                </Button>
                <Text as="span">or</Text>
                <Button
                  variant="linkPrimary"
                  onClick={(event: MouseEvent) => void handleResend(event, SmsDeliveryMethods.voice)}
                  css={{
                    padding: 'space.0',
                  }}
                >
                  call me again
                </Button>
              </Box>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { VerifyStep };
